import React, { useCallback, useEffect, useRef, useState } from 'react';
import Product from '../pages/products/Product';

import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { StyledProductsContent, StyledPagination } from '../pages/products/styles/ProductsStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { getCookie } from 'cookies-next';
import Link from 'next/link';
import { Container, Row } from 'react-bootstrap';

import messages from '../../../src/messages';
import { FormattedMessage } from 'react-intl';
import DialogAlert from '../global/dialogs/DialogAlert';
import * as Sentry from '@sentry/nextjs';
import NewProduct from '../pages/newproduct/NewProduct';

SwiperCore.use([Navigation, Pagination, A11y]);

export default function Products({
    props: any,
                                     setCurrentPageAndLoad,
    openDuplicateDialogAndSetIDZ,
    openDeleteDialogAndSetIDZ,
    openDeleteDialogAndSetIDZMultiple,
    openMoveDialogAndSetIDZ,
    openRenameDialogAndSetIDZ,
    deleteProductCheck,appExternal,
                                     products,
                                     filterActive,

                                     loadProducts,

                                     selectingMultiple,
                                     filterBrands,
                                     filterClients,
                                     filterEditions,
                                     selectingFunction,
                                     totalPages,
                                     currentPage,rootPackageGroups
}) {





    const [threeInited, setThreeInited] = useState(false);

    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    let handleTriggerWarning = () => {

    };

    const [currentPageInner, setCurrentPageInner] = useState(1);

    useEffect(() => {

        setCurrentPageInner(currentPage);

    }, [currentPage]);

    return (
        <div style={{ padding: '0', paddingTop: '15px' }}>
            {}
            {products.length === 0 && !filterActive ? (
                <div data-closemodalmenu={true}>
                    <Container data-closemodalmenu={true}>
                        <Row style={{ paddingBottom: '50px', paddingTop: '50px' }}>
                            <h2 id="mainHeaderWelcome" style={{ textAlign: 'center', position: 'relative', color: "#666", fontWeight: "100!important" }} data-closemodalmenu={true}>
                                <FormattedMessage {...messages.products_welcome_header}></FormattedMessage></h2>


                        </Row>
                        <Row>

                            {rootPackageGroups.length > 0 ? (
                                    <>


                                        {rootPackageGroups.map((value, index) => {

                                            return (
                                                <div className="welcomeProduct">
                                                    <Link
                                                        href={'new-product/?category='+value.uuid}>
                                                        {value.name === 'Cans'
                                                            ?
                                                            <img
                                                                style={{
                                                                    maxWidth: '300px',
                                                                    top: '-15px',
                                                                    position: 'relative',
                                                                    left: ' 0px',
                                                                }}
                                                                src={'/images/cans.png'}
                                                            />
                                                            : null}
                                                        {value.name === 'Cartons' || value.name === 'Combibloc'
                                                            ?
                                                            <img
                                                                style={{
                                                                    maxWidth: '300px',
                                                                    top: '-15px',
                                                                    position: 'relative',
                                                                    left: ' 0px',
                                                                }}
                                                                src={ '/images/combibloc.jpg'}
                                                            />
                                                            : null}
                                                        {value.name === 'Shrink Foils'
                                                            ?
                                                            <img
                                                                style={{
                                                                    maxWidth: '300px',
                                                                    top: '-15px',
                                                                    position: 'relative',
                                                                    left: ' 0px',
                                                                }}
                                                                src={ '/images/shrinky.jpg'}
                                                            />
                                                            : null}

                                                        {value.name === 'Glass Bottles'
                                                            ?
                                                            <img
                                                                style={{
                                                                    maxWidth: '300px',
                                                                    top: '-15px',
                                                                    position: 'relative',
                                                                    left: ' 0px',
                                                                }}
                                                                src={ '/images/glassbotle.jpg'}
                                                            />
                                                            : null}
                                                        {value.name === 'Cups'
                                                            ?
                                                            <img
                                                                style={{
                                                                    maxWidth: '300px',
                                                                    top: '-15px',
                                                                    position: 'relative',
                                                                    left: ' 0px',
                                                                }}
                                                                src={ '/images/cups.jpg'}
                                                            />
                                                            : null}


                                                        <h3 style={{ position: 'relative', top: '5px' }}
                                                            className="text-center mb-0 mt-1">

                                                            {value.name === 'Cans'
                                                                ?
                                                                <FormattedMessage {...messages.products_type_cans}></FormattedMessage>
                                                                : null}
                                                            {value.name === 'Cartons' || value.name === 'Combibloc'
                                                                ?
                                                                <FormattedMessage {...messages.products_type_combiblocs}></FormattedMessage>
                                                                : null}
                                                            {value.name === 'Shrink Foils'
                                                                ?
                                                                <FormattedMessage {...messages.products_type_shrink_foils}></FormattedMessage>
                                                                : null}

                                                            {value.name === 'Glass Bottles'
                                                                ?
                                                                <FormattedMessage {...messages.products_type_glassbottles}></FormattedMessage>
                                                                : null}
                                                            {value.name === 'Cups'
                                                                ?
                                                                <FormattedMessage {...messages.products_type_cups}></FormattedMessage>
                                                                : null}


                                                            {/*   <FormattedMessage {...messages.products_type_cans}></FormattedMessage>*/}
                                                        </h3>
                                                    </Link>
                                                </div>

                                            );
                                        })}
                                    </>
                                ) :
                                null}


                        </Row>
                    </Container>
                </div>
            ) : (
                <>

                </>
            )}

            <StyledProductsContent

                style={products.length === 0 ? { zIndex: '1', position: 'relative' } : null}
                id={'productsContent'}
            >
                <div id="content" style={{ textAlign: 'left' }} data-closemodalmenu={true}>
                    {products.length > 0 && products !== undefined ? (
                        <>
                            {products.map((value, index) => {

                                return (
                                    <Product
                                        appExternal={appExternal}
                                        openDuplicateDialogAndSetIDZ={openDuplicateDialogAndSetIDZ}
                                        openDeleteDialogAndSetIDZ={openDeleteDialogAndSetIDZ}
                                        openMoveDialogAndSetIDZ={openMoveDialogAndSetIDZ}
                                        openRenameDialogAndSetIDZ={openRenameDialogAndSetIDZ}
                                        productType={'GRID'}
                                        withThumbnail={true}
                                        triggerWarning={triggerWarning}
                                        productThumbnail={value.thumbnail.fallback}
                                        threeID={'threeGrid' + value.uuid}
                                        deleteProductCheck={deleteProductCheck}
                                        key={'Product' + index}
                                        productID={value.uuid}
                                        productSelected={value.productSelected}
                                        selectingMultiple={selectingMultiple}
                                        productName={value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}
                                        productFullName={value.name}
                                        classes={`showProduct `}
                                        productBrand={filterBrands.map((valueBrands, index) => {
                                            if (valueBrands.uuid === value.groups.brand) {
                                                return valueBrands.name;
                                            }
                                        })}
                                        productClient={filterClients.map((valueClients, index) => {
                                            if (valueClients.uuid === value.groups.client) {
                                                return valueClients.name;
                                            }
                                        })}
                                        productEdition={filterEditions.map((valueEdition, index) => {
                                            if (valueEdition.uuid === value.groups.edition) {
                                                return valueEdition.name;
                                            }
                                        })}
                                        productVersions={value.versions.length}
                                        productVersionsList={value.versions}
                                        productVolume={value.package.volume.name}
                                        productPackageGroup={value.package.groups[0].name}
                                        productPackageLastUUID={value.last_updated_version}
                                        clickEvent={e => selectingFunction(e)}
                                    />
                                );
                            })}
                        </>
                    ) : null}
                </div>
            </StyledProductsContent>

            <DialogAlert
                toggleAlert={ (toggleAlert: () => void) => {
                    handleTriggerWarning = toggleAlert;
                } }
                alertType={"warning"}
                message={warningMessage}
            />

            {totalPages > 1 ? (
                <StyledPagination className={'pagination'}>

                    {currentPageInner > 1 ? (
                        <button onClick={() => setCurrentPageAndLoad(currentPageInner - 1)}>
                            {' '}
                            <FontAwesomeIcon icon={faChevronLeft} />{' '}
                        </button>
                    ) : null}

                    <span>
                        {currentPageInner} of {totalPages}
                    </span>

                    {currentPageInner < totalPages ? (
                        <button onClick={() => setCurrentPageAndLoad(parseInt(currentPageInner, 10) + 1)}>
                            {' '}
                            <FontAwesomeIcon icon={faChevronRight} />{' '}
                        </button>
                    ) : null}
                </StyledPagination>
            ) : null}
        </div>
    );
}
