import React, {useEffect, useRef, useState} from 'react';

import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faComment, faEye, faPencil,faXmark} from "@fortawesome/free-solid-svg-icons";

import { borderRadius22, borderRadius50percent } from '../../global/Variables.js';
import messages from '../../../../src/messages';
import { FormattedMessage } from 'react-intl';



const StyledShareTeam = styled.div`

    width: 100%;
    display: block;

    margin-top: 20px;
  
    border-radius:${borderRadius22};
    padding-top: 10px;
    padding-bottom: 10px;

  overflow:hidden;
  transition:all 0.3s ease;
    height: 73px;
 
  .openingTeam{
    position: absolute;
    left: 0;
    top: 47px;
    width: 65%;
    overflow: auto;
    z-index:9;
      height: 73px;
  }
  &.open{
    overflow: auto;
    height:fit-content;
    background: lightgray;
  }
  &:hover{
    background: lightgray;
  }
    
          @media screen and (max-height: 800px) {
            margin-top: 10px;
            }
            
    >div{
      display:inline-block;
      float:left;
    }
    .teamIcon{
      width:13%;

        img{
            width:53px;
            height:53px;
            border-radius: ${borderRadius50percent};

        }

        @media(max-width:550px){
            width:63px;
            padding-left: 10px;
        }
    }
    .teamContent{
    width:100%;
    }
    .teamName{
      width:44%;
      text-align:left;

        text-align: left;
        margin-top: 17px;
        margin-left: 7px;

        @media(max-width:550px){
            width:100px;
        }
        
      p{
        font-size:16px;
        opacity:80%;
        line-height: 20px;
        margin:0;
      }
    }
    .teamActions{
      width:40%;

        @media(max-width:550px){
            width: 100%;

            text-align: left;
            >button{
                margin-left: 63px;
                margin-top: 0 !important;
            }
        }
      button{
      float:right;
        border:none;
        background:transparent;
        transition: all 0.3s ease;
          border-radius:${borderRadius22};
        padding:5px;
        padding-right:15px;
        padding-left:15px;
        font-size:16px;
        color:rgba(0,0,0,0.6);
        &:hover,
        &.active{
          color:black;
          background:rgba(0,0,0,0.15);
        }
        &.owner{
            .downIcon{
            color:transparent;
            }
        }
            &.owner:hover,
      &.owner.active{
      cursor:default;
            color:rgba(0,0,0,0.6);
      background:none !important;
      }
      
        svg{
          margin-left:3px;
        }
      }

      .teamPopup{
        display:none;
        width: 200px;
      height: 152px;
        background: white;
          border-radius:${borderRadius22};
        box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.15);
        z-index: 9;
        //display: block;
        margin-top:40px;
        position: absolute;

        padding:4px;
        &.active{
          display:block;
        }
        hr{
          margin: 0;
          margin-top:4px;
          margin-bottom:4px;
        }
        ul{
          list-style-type:none;
          text-align:left;
          padding:0;
          margin:0;

          li{
            transition:all 0.3s ease;
            font-size:18px;
            padding:5px;
              border-radius:${borderRadius22};
            padding-left:15px;
            line-height:26px;
            
           
            

            span{
              transition:all 0.3s ease;
              margin-left:10px;
              color:rgba(0,0,0,0.6);
            }
            svg{
              transition:all 0.3s ease;
              color:rgba(0,0,0,0.6);
                width: 1em;
            }

            &:hover{
                cursor:pointer;
              background:rgba(0,0,0,0.15);
              span,
              svg{
                color:rgba(0,0,0,1);
              }
            }
            &.active{
              color:#3d55d7;
              span,
              svg{
                 color:#3d55d7;
                
              }
            }
          }
        }
      }
    }

  
`;


export default function ShareTeam ({  props: any, typeShare, changeTeamRole,shareTeamIcon, shareTeamName, shareTeamEmail, shareTeamRole,children}) {
    const [shareTeamPopupOpened, setShareTeamPopupOpened] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!ref.current?.contains(event.target)) {
           //     alert("Outside Clicked.");
                setShareTeamPopupOpened(false);
            }
        };

        window.addEventListener("mousedown", handleOutSideClick);

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [ref]);




    const changeTeamRoleInner = (role, type) => {
        changeTeamRole(role, type);
        setShareTeamPopupOpened(false);
    };


    const [shareTeamOpen, setShareTeamOpen] = useState(false);

    return (
        <>
            <StyledShareTeam className={`${shareTeamOpen ? 'open' : ""} `} key={shareTeamName+"key"}>


                <div className={"openingTeam"} onClick={()=> setShareTeamOpen(shareTeamOpen ? false : true)}>

                </div>

                    <div className={"teamIcon"}>
                        <img data-closemodalautocomplete={true} style={{maxWidth:"100%"}} src={shareTeamIcon ? shareTeamIcon : "/images/logo.svg"}/>
                    </div>
                    <div  className={"teamName"}>
                        <p data-closemodalautocomplete={true}><b>{shareTeamName}</b></p>

                    </div>
                    <div  className={"teamActions"}>



                            <button data-closemodalautocomplete={true} onClick={() => setShareTeamPopupOpened(shareTeamPopupOpened ? false : true)}  style={{marginTop:"10px"}}>
                                { shareTeamRole === "none" ? <FormattedMessage {...messages.products_modal_share_team_roles_none}></FormattedMessage> : null}
                                {shareTeamRole === "owner" ? <FormattedMessage {...messages.products_modal_share_team_roles_owner}></FormattedMessage> : null}
                                { shareTeamRole === "view" ? <FormattedMessage {...messages.products_modal_share_team_roles_viewer}></FormattedMessage> : null}

                                {shareTeamRole === "comment" ?  <FormattedMessage {...messages.products_modal_share_team_roles_commenter}></FormattedMessage>  : null}

                                {shareTeamRole === "edit" ?  <FormattedMessage {...messages.products_modal_share_team_roles_editor}></FormattedMessage>  : null}&nbsp;
                                <FontAwesomeIcon icon={faCaretDown} className={"downIcon"}/>
                            </button>

                        <div className={`${shareTeamPopupOpened ? 'active teamPopup' : "teamPopup"} `} ref={ref}>
                            <ul>
                                <li data-closemodalautocomplete={true}
                                    onClick={() => changeTeamRoleInner("none", typeShare)}
                                    className={`${ shareTeamRole === "none" ? 'active' : null} `}>
                                    <FontAwesomeIcon icon={faXmark}
                                                     className={"downIcon"} /><span><FormattedMessage {...messages.products_modal_share_roles_none}></FormattedMessage></span>
                                </li>
                                <li data-closemodalautocomplete={true}
                                    onClick={() => changeTeamRoleInner("view", typeShare)}
                                    className={`${ shareTeamRole === "view" ? 'active' : null} `}>
                                    <FontAwesomeIcon icon={faEye}
                                                     className={"downIcon"} /><span><FormattedMessage {...messages.products_modal_share_roles_viewer}></FormattedMessage></span>
                                </li>
                                <li data-closemodalautocomplete={true}
                                    onClick={() => changeTeamRoleInner("comment", typeShare)}
                                    className={`${shareTeamRole === "comment" ? 'active' : null} `}><FontAwesomeIcon
                                    icon={faComment}
                                    className={"downIcon"} /><span><FormattedMessage {...messages.products_modal_share_roles_commenter}></FormattedMessage></span>
                                </li>
                                <li data-closemodalautocomplete={true}
                                    onClick={() => changeTeamRoleInner("edit", typeShare)}
                                    className={`${shareTeamRole === "edit" ? 'active' : null} `}><FontAwesomeIcon
                                    icon={faPencil}
                                    className={"downIcon"} /><span><FormattedMessage {...messages.products_modal_share_roles_editor}></FormattedMessage></span>
                                </li>
                                {/*   <hr/>
                                <li data-closemodalautocomplete={true}><span><FormattedMessage {...messages.products_modal_share_roles_add_ownership}></FormattedMessage> </span></li>
                                <li data-closemodalautocomplete={true}><span><FormattedMessage {...messages.products_modal_share_roles_remove_access}></FormattedMessage></span></li>*/}
                            </ul>
                        </div>
                    </div>

                <div className={"teamContent"} data-closemodalautocomplete={true}>
                    {children}
                </div>


            </StyledShareTeam>

        </>
    )
}